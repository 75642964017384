import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default ({ location })  => (
    <Layout>
        <SEO 
            pagetitle="about - このサイトについて"
            pagepath={location.pathname}
        />
        <main id="about">
            <div className="inner">
                <h1>ABOUT
                    <span>- 私たちについて</span>
                </h1>
                <div className="aboutHero">
                        <div className="topAboutContent">
                            <div className="daihyo"><img src="/images/daihyou-kouno.svg" alt="代表" /></div>
                            <div className="topAboutText">
                                <p>
                                    税理士の甲能知樹です。<br />
                                    融資などこれからの資金調達について前向きに発信しています！
                                    これまでにたくさんの融資支援の経験をしてきました。事業計画以前の事前準備から情報共有する必要を感じ、サイトの運営を行っています。
                                </p>
                                <div>甲能税理士事務所</div>
                            </div>
                        </div>
                </div>
                <div className="aboutContent">
                    <h3>「自分の夢を叶えたい」</h3>
                    <h3>「好きなことを仕事にしたい」</h3>
                    <h3>「自分の力を試してみたい」</h3>
                    <div className="aboutText">
                        <p>
                            こういった起業家の決断を支援できればと思い立ち上げました。<br className="aboutTextBr"/>
                        </p>
                        <p>
                            当サイトは甲能税理士事務所（大阪市淀川区）が運営しています。<br />
                            起業する際のお金の問題をクリアにして<br />
                            起業家の目標実現を支援することが私たちのVISIONです。
                        </p>
                    </div>
                    <h3>
                        お金をClearに、起業をもっとClearに<br />
                    </h3>
                    <div className="aboutText">
                        <p>
                            起業をする際は分からないことが多いです。<br />
                            資金を借りるにしても前もって準備することが大事です。
                        </p>
                        <p>
                            融資について最新情報を提供します。<br />
                            起業するための第一歩や事業拡大の支援をするために<br />
                            融資の方法や申込みまでにできることの情報発信を始めました。
                        </p>
                        <p>
                            チャレンジする人のハードルを一つでも下げたい。<br className="aboutTextBr"/>
                            その思いで発信していきます。
                        </p>
                    </div>
                </div>
            </div> 
            <div className="breadCrumb">
                <ol>
                    <li>
                    <Link to={`/`}>TOP</Link>
                    </li>
                    <li>
                        about - 私たちについて
                    </li>
                </ol>
            </div>
        </main>
    </Layout>
)


















